/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS620M
 * 화면 설명: 영업/활동관리 > 대체/유의계약 면담현황(관리자용)
 */
<template>
  <ur-page-container class="msp" type="subpage" title="대체/유의계약 면담현황" :show-title="true" :topButton="true"
      @on-scroll-bottom="fn_ScrollBottomHandler">   
    <!-- Content영역 -->
    <template #frame-header-fixed>
      <!-- 지점장만 표시 -->
      <ur-box-container direction="column" alignV="start" class="topTip2"> 
        <ul class="bullet-type-3 crTy-bk7 fs14rem"><li>FC 면담 후 진행 바랍니다.</li></ul>
        <ul class="bullet-type-3 crTy-bk7 fs14rem"><li>면담을 하지 않은 계약만 [면담] 버튼이 활성화 됩니다.</li></ul>
      </ur-box-container>
      <!--// 지점장만 표시 끝 -->
      <!-- 상세 검색 button -->
      <ur-box-container direction="row" alignV="start" class="directiveButtonWrap"> 
        <mo-icon class="refreshIcon" icon="refreshIcon" @click="initDirective">refreshIcon</mo-icon>
        <div class="directiveButtons">
          <mo-button class="directiveButton selectOrgBtn" @click="openOrgPopup">{{orgNm}}</mo-button>
          <msp-bottom-select :items="selectState" v-model="state1" class="ns-dropdown-sheet directiveDropdown selectStateDropdown" :placeholder="state1" bottom-title="면담현황" closeBtn @input="directiveDropdownInput1" />
          <mo-button class="directiveButton approvalDateBtn" @click="openApprovalDatePopup">{{requestDate}}</mo-button>
          <mo-button class="directiveButton planTypeBtn" @click="openPlanTypePopup">{{planType}}</mo-button>
        </div>
      </ur-box-container>
      <!-- 상세 검색 button 끝 -->
      <!-- 건수, 정렬조건 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title align-item-center">
              <span class="sum">총<span class="count">{{rsltCnt}}</span>건</span>
              <!--<msp-bottom-select ref="bottomSelect" :items="items" v-model="sorting" @input="onSelect" class="ns-dropdown-sheet fs14rem type-1 mt4 selectSorting" placeholder="정렬" bottom-title="정렬 선택" closeBtn />-->
            </div>
          </div>
        </mo-list-item> 
      </ur-box-container>
      <!-- 건수, 정렬조건 끝-->
    </template>
    <!-- Data 영역(지점장/FC data 구분) -->
    <ur-box-container v-if="!isNodata" direction="column" alignV="start" class="msp-ps-wrap">      
      <ur-box-container direction="column" alignV="start">
        <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand">
          <ur-box-container alignV="start" componentid="" direction="column">      
            <mo-list :list-data="rsltItemList" ref="moList">
              <template #list-item="{item}">      
                <mo-list-item class="plr24">
                  <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100 mt20" btn-area-class="fexTy3-1 mb0">
                    <template #title>
                      <div class="txtSkip fs19rem fwb">{{item.zaPlanNm}}</div>
                      <div class="mt10 mb4 expandItems">
                        <span class="contentsHead1 min50">컨설턴트</span> <span class="contentsData1 expandItem">{{item.zaClctCnsltNoNm}}({{item.zaClctCnsltNo}})</span>
                        <em class="em_normal">|</em>
                        <span><span class="contentsHead1 min40">설계일</span> <span class="contentsData1 expandItem">{{item.zaPblYmdLabel}}</span></span>
                      </div>
                      <div class="mt10">
                        <span class="contentsHead1">보험료</span> <span class="crTy-blue3 fs16rem fwb">{{$bizUtil.numberWithCommas(item.prm)}}원</span>
                      </div>
                    </template>
                    <template #btn>
                      <mo-button class="link-arrow down"></mo-button>
                    </template>
                    <template #content>
                      <div class="list-item-detail gray_style margin--full pt20 pb20 bd-T-Ty1 mt10">
                        <ur-box-container class="con--padding">
                          <div class="contentsRow">
                              <span class="contentsHead2 min80">고객명</span><span class="contentsData2">{{item.zaCustNm}}</span>
                          </div>
                          <div class="contentsRow mt7">
                              <span class="contentsHead2 min80">영수증번호</span><span class="contentsData2">{{item.zaPlanId}}</span>
                          </div>
                          <div class="contentsRow mt7">
                              <span class="contentsHead2 min80">설계종류</span><span class="contentsData2">{{item.zaPlanTypeNm}}</span>
                          </div>
                          <div class="contentsRow mt7">
                              <span class="contentsHead2 min80">면담현황</span><span class="contentsData2">{{item.zaSancrCdNm}}</span>
                          </div>
                          <div class="contentsRow mt7">
                              <span class="contentsHead2 min80">면담일</span><span class="contentsData2">{{item.zaSancrYmdLabel}}</span>
                          </div>
                        </ur-box-container>
                      </div>
                    </template>
                  </msp-expand>
                </mo-list-item>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box expand_bottomline">
                  <div class="ns-btn-relative-area mt20 mb20">
                    <div class="ns-btn-relative-s">
                      <div class="relative-div grid-temp-col31 mb0">
                        <!-- 지점장/FC에 따라 button 구분 -->
                        <mo-button componentid="" v-if="item.zaSancrCd === '10'" class="ns-btn-round white ml0 mr0" @click="fn_openPopup(item)">면담</mo-button>
                        <mo-button componentid="" v-if="item.zaSancrCd === '12'" disabled class="ns-btn-round white ml0 mr0">면담완료</mo-button>
                      </div>
                    </div>
                  </div>
                </ur-box-container>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
    <!-- Data 영역 끝 -->
    <!-- content 영역 끝 -->

    <!-- Popup 사전결재 -->
    <!--
    <mo-bottom-sheet ref="confirmPopup" :close-btn="closeBtn"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        <div class="ns-btn-close" @click="closeConfirmPopup" name="닫기"></div>
      </template>
       
      <div class="customer-info-txt txt-center">
        <div class="popupMessageBold">사전결재 하시겠습니까?</div>
        <div class="popupMessageSub">본 발행건은 {{planTypeNm}}입니다.</div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="closeConfirmPopup" class="ns-btn-round white">아니오</mo-button>
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="doConfirm" class="ns-btn-round blue">예</mo-button>
          </div>
          <div class="popupButtonTip">※ 지점접수만 가능</div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    -->
    <!-- Popup 사전결재 끝 -->

    <!-- Popup 컨설턴트 선택 -->
    <mo-bottom-sheet ref="orgPopup" :close-btn="closeBtn"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        <div>지점선택</div>
        <div class="ns-btn-close" @click="closeOrgPopup" name="닫기"></div>
      </template>
       
      <div class="customer-info-txt">
        <!--<div class="popSelectSubject">조직</div>-->
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <msp-bottom-select class="ns-dropdown-sheet" :items="lv_HofOrgData" v-model="lv_HofOrg.key" @input="fn_GetDofData" :disabled="lv_OrgAuth.isEntpwRolYn==='N'" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="사업부선택" closeBtn scrolling placeholder="사업부선택"/>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
          <msp-bottom-select class="ns-dropdown-sheet" :items="lv_DofOrgData" v-model="lv_DofOrg.key" @input="fn_GetFofData" :disabled="lv_OrgAuth.isHofRolYn==='N'" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="지역단선택" closeBtn scrolling placeholder="지역단선택"/>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
          <msp-bottom-select class="ns-dropdown-sheet" ref="refFofOrgData" :items="lv_FofOrgData" v-model="lv_FofOrg.key" :disabled="lv_OrgAuth.isDofRolYn==='N'" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="지점선택" closeBtn scrolling placeholder="지점선택"/>
        </ur-box-container>
        <!--
        <div class="mt20 popSelectSubject">담당 컨설턴트</div>
        <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
          <msp-bottom-select class="ns-dropdown-sheet" ref="refFofCnsltData" :items="lv_FofCnsltData" v-model="lv_FofCnslt.key" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="컨설턴트 선택" closeBtn scrolling placeholder="컨설턴트 선택"/>
        </ur-box-container>
        -->
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="selectOrg" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- Popup 컨설턴트 선택 끝 -->

    <!-- Popup 청약서발행일 선택 -->
    <mo-bottom-sheet ref="approvalDatePopup" :close-btn="closeBtn"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        <div>청약서발행일</div>
        <div class="ns-btn-close" @click="closeApprovalDatePopup" name="닫기"></div>
      </template>
       
      <div class="customer-info-txt narrow">
        <div class="ns-date-picker multi">
          <mo-date-picker v-model="dateValue1" init="today" placeholder="시작일" /><span>~</span>
          <mo-date-picker v-model="dateValue2" init="today" placeholder="종료일" />
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="selectApprovalDate" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- Popup 청약서발행일 선택 끝 -->

    <!-- Popup 설계 종류 선택 -->
    <mo-bottom-sheet ref="planTypePopup" :close-btn="closeBtn"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        <div>설계 종류</div>
        <div class="ns-btn-close" @click="closePlanTypePopup" name="닫기"></div>
      </template>
       
      <div class="customer-info-txt narrow">
        <div class="planTypePopupDropdownWrap">
          <msp-bottom-select ref="bottomSelect" :itemValue="'key'" :itemText="'label'" v-model="planTypeSel.key" :items="planTypeList" underline class="ns-dropdown-sheet" placeholder="구분" bottom-title="설계 구분" closeBtn/>
          <!--<msp-bottom-select ref="bottomSelect" :itemValue="'key'" :itemText="'label'" v-model="planKindSel2.key" :items="planKindList2" underline class="ns-dropdown-sheet" placeholder="구분" bottom-title="설계 구분" closeBtn/>-->
          <!--<msp-bottom-select :items="planKinds" v-model="planType1" underline class="ns-dropdown-sheet" placeholder="구분" bottom-title="설계 구분" closeBtn />-->
          <!--<msp-bottom-select :items="planKinds" v-model="planType2" underline class="ns-dropdown-sheet" placeholder="구분" bottom-title="설계 구분" closeBtn />-->
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="selectPlanType" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- Popup 설계 종류 선택 끝 -->

    <!-- 토스트 -->
    <mo-snackbar ref="stackableSnackbar" stackable :duration="5000" /> 
  </ur-page-container>
</template>
<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import Msg from '@/systems/webkit/msg/msg'
import PSConstants from '@/config/constants/psConstants'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import MSPPS621P from '@/ui/ps/MSPPS621P'
import moment from 'moment'
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPPS620M",
    screenId: "MSPPS620M",
    components: {
      'MSPPS621P'               : MSPPS621P
    },
    name: "",
    screenId: "",
    props:{
      closeBtn:{
        type:Boolean,
        default:false
      },
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
    beforeCreate () {},//beforeCreate    
    
    created() {
      // this.fn_ServiceData('S')
    },
    // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
    beforeMount () {},//beforeMount

    mounted () {
      // 정렬옵션 필드 셋팅
      this.fn_SetOrderByFields()
      //초기 검색 조회 셋팅  
      // this.rtnSearchPopupData.searchScVal   = PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key
      // this.rtnSearchPopupData.searchKeyword = ''
      this.fn_Reset()            
      this.fn_GetFofRol()
      this.fn_ServiceData('S')

      // 화면접속로그 등록(필수) >> 열어야함.
      // this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
    beforeUpdate () {},//beforeUpdate

    // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
    updated () {},//updated

    // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
    beforeDestroy () {},//beforeDestroy

    // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
    destroyed () {
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    },//destroyed

    // Life Cycle 인스턴스가 KeepAlive true 이고 다시 본 화면으로 접근시 Active (활성화) 된 후 호출 됨
    activated () {
      return
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        stndKey: '', // 페이징 Next Key
        actionSlot: '', // 고객명 검색 slot
        isNodata: false, // 데이터 없음 Flag            
        show: true,
        sorting:'',
        rsltCnt:0,  // 조회결과 누적 데이터 길이
        itemData621P:{},
        sortingItems:[],
        lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
        lv_HofOrg: {key: '00000000', label: '전사'}, // 선택사업부
        lv_DofOrg: {key: '0', label: '전체'}, // 선택지역단
        lv_FofOrg: {key: '0', label: '전체'}, // 선택지점
        lv_FofCnslt: {key: '0', label: '전체'}, // 선택컨설턴트
        lv_HofOrgData: [], // 사업부 목록
        lv_DofOrgData: [], // 지역단 목록
        lv_FofOrgData: [], // 지점 목록
        lv_FofCnsltData: [], // 컨설턴트 목록
        lv_OrgAuth: {}, // 지점권한
        orgNm:'지점',
        orgData:'',
        state1: '면담현황',
        requestDate:'청약서발행일',
        planType:'설계 종류',
        planTypeNm:'',
        input: {
            sancYn:'',
            fromYmd:'',
            toYmd:'',
            planType:'',
            hofOrgNo:'',
            dofOrgNo:'',
            fofOrgNo:''
        },
        planTypeSel: {key: '', label: ''},
        planTypeList: [
          {key: '99', label: '전체'},
          {key: '80', label: '본인계약'},
          {key: '82', label: '분산모집계약'},
          {key: '83', label: 'JUST달성계약'},
          {key: '81', label: '단기반복해지계약'},
          {key: '84', label: '대체계약'}
        ],
        rsltItemList:[], // 조회결과 리스트 데이터
        // rsltItemList: [
        //   {
        //     zaPlanNm:'[지점장]급여 실손의료비 재발행 요청 가나다라',
        //     zaClctCnsltNoNm:'이세별 가나다라마바사',
        //     zaPblYmdLabel:'12-05',
        //     prm:'200000000',
        //     zaCustNm:'가마다라마바사 James',
        //     //insrdChnlCustId:'01234567890123456789',
        //     zaClctCnsltNo:'0001057465',            
        //     zaDofOrgNm:'테스트1지역단',            
        //     zaFofOrgNm:'테스트2지점',            
        //     zaPlanId:'123-456789-000000',
        //     zaPlanTypeNm:'대체계약',
        //     zaSancrCd:'02',
        //     zaSancrCdNm:'결재완료',
        //     zaSancrYmdLabel:'2024-10-16',
        //     zaPlanType:'84',
        //     zaUserId:'1000029725',
        //     isCmpltSanc:true
        //   },
        //   {
        //     zaPlanNm:'[지점장]급여 실손의료비 재발행 요청',
        //     zaClctCnsltNoNm:'강역선',
        //     zaPblYmdLabel:'12-05',
        //     prm:'100000000',
        //     zaCustNm:'가마다라마바사 James',
        //     //insrdChnlCustId:'01234567890123456789',
        //     zaClctCnsltNo:'0001057465',            
        //     zaDofOrgNm:'테스트1지역단',            
        //     zaFofOrgNm:'테스트2지점',            
        //     zaPlanId:'123-456789-000000',
        //     zaPlanTypeNm:'분산모집계약',
        //     zaSancrCd:'01',
        //     zaSancrCdNm:'미결재',
        //     zaSancrYmdLabel:'-',
        //     zaPlanType:'82',
        //     zaUserId:'1000029725',
        //     isCmpltSanc:false
        //   },
        // ],
        selectState: ['전체', '면담전', '면담완료'],
        dateValue1:'',
        dateValue2:''
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      onSelect(value) {
        this.selectedItem = this.$refs.bottomSelect.getSelectedItem()
      },
      moreShow() {
        this.show = !this.show
      },
      open() { this.$refs.nsbottomsheet.open()},
      close() { this.$refs.nsbottomsheet.close()},

      initDirective() {
        this.orgNm = '지점'
        this.state1 = '면담현황'
        this.requestDate = '청약서발행일'
        this.planType = '설계 종류'
        this.planTypeSel.key = ''
        document.querySelector('.directiveDropdown.selectStateDropdown').classList.remove('checked')
        let directiveButtons = document.getElementsByClassName('directiveButton')
        for (let i = 0; i < directiveButtons.length; i++){
          directiveButtons[i].classList.remove('checked')
        }
        this.dateValue1 = moment(new Date()).format('YYYY-MM-DD')
        this.dateValue2 = moment(new Date()).format('YYYY-MM-DD')
        this.input.sancYn = ''
        this.input.fromYmd = ''
        this.input.toYmd = ''
        this.input.planType = ''
        this.input.hofOrgNo = ''
        this.input.dofOrgNo = ''
        this.input.fofOrgNo = ''
        
        this.fn_Reset()            
        this.fn_GetFofRol()
        this.fn_ServiceData('S')
      },
      /******************************************************************************
      * Function명 : fn_GetDofData
      * 설명       : 지역단 조회 ( SearchOrg 참고 )
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_GetDofData ( param ) {
        console.log('fn_GetDofData >> ', param)
        if ( param ) {
          this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
          if (this.lv_DofOrgData) { this.lv_DofOrgData.slice(0) }
          if (this.lv_FofOrgData) { this.lv_FofOrgData.slice(0) }
          if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
          this.lv_DofOrg = {key: '0', label: '전체'}
          this.lv_FofOrg = {key: '0', label: '전체'}
          this.lv_FofCnslt = {key: '0', label: '전체'}
          if (this.lv_HofOrg.key !== '0' && this.lv_HofOrgData.length > 1 ) {
            this.$bizUtil.selListDofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_GetFofData
      * 설명       : 지점 조회
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_GetFofData (param) {
        console.log('fn_GetFofData >> ', param)
        if ( param ) {
          this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
          this.lv_SearchParam.dofOrgNo = this.lv_DofOrg.key
          if (this.lv_FofOrgData) { this.lv_FofOrgData.slice(0) }
          if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
          this.lv_FofOrg = {key: '0', label: '전체'}
          this.lv_FofCnslt = {key: '0', label: '전체'}
        }
        this.$bizUtil.selListFofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      },
      /******************************************************************************
      * Function명 : fn_GetFofCnsltData
      * 설명       : 컨설턴트 조회
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_GetFofCnsltData (param) {
        console.log('fn_GetFofCnsltData >> ', param)
        if ( param ) {
          this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
          this.lv_SearchParam.dofOrgNo = this.lv_DofOrg.key
          this.lv_SearchParam.fofOrgNo = this.lv_FofOrg.key
          this.lv_SearchParam.orgNo = this.lv_FofOrg.key
          if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
          this.lv_FofCnslt = {key: '0', label: '전체'}
          this.$bizUtil.selListFofCnslt(this.lv_SearchParam, this.fn_OrgSearchCallBack)
        } else {
          this.lv_SearchParam.hofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo
          this.lv_SearchParam.dofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstDofNo
          this.$bizUtil.selListFofCnslt(this.lv_SearchParam, this.fn_OrgSearchCallBack)
        }
      },
      /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 시스템 옵션 조회
      * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
      * Return     : BCOptnMngSrchOSVO
      ******************************************************************************/
      fn_OrgSearchCallBack ( rtnData, sType ) {
        console.log('fn_OrgSearchCallBack >>> ', rtnData, sType)
        if (sType === 'CNSLT') {
          this.lv_FofCnsltData.slice(0)
          this.lv_FofCnsltData = rtnData
          let isSelected = false
          // 본인으로 설정되는 걸 막기 위해 Comment 처리함. K.Cho
          // if (this.lv_IsFirst) {
          //   for ( let oData of rtnData ) {
          //     if ( oData.key === this.getStore('userInfo').getters.getUserInfo.userId ) {
          //       this.lv_FofCnslt = oData
          //       isSelected = true
          //       break
          //     }
          //   }
          //   if ( !isSelected ) {
          //     this.lv_FofCnslt = {key: this.getStore('userInfo').getters.getUserInfo.userEno,
          //       label: this.getStore('userInfo').getters.getUserInfo.userNm}
          //   }
          this.lv_IsFirst = false
          // }
        } else {
          this.lv_HofOrgData = rtnData[0]
          this.lv_DofOrgData = rtnData[1]
          this.lv_FofOrgData = rtnData[2]
          console.log('this.lv_IsFirst >> ', this.lv_IsFirst)
          console.log(this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo)
          console.log(this.getStore('userInfo').getters.getUserInfo.onpstDofNo)
          console.log(this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo)
          if (this.lv_IsFirst) {
            let isSelHof = false
            for ( let oData of rtnData[0] ) {
              if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo ) {
                this.lv_HofOrg = oData
                isSelHof = true
                break
              }
            }
            if (!isSelHof) this.lv_HofOrg = {key: '00000000', label: '전사'}

            let isSelDof = false
            for ( let oData of rtnData[1] ) {
              if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstDofNo ) {
                this.lv_DofOrg = oData
                isSelDof = true
                break
              }
            }
            if (!isSelDof || !isSelHof) this.lv_DofOrg = {key: '0', label: '전체'}

            let isSelFof = false
            for ( let oData of rtnData[2] ) {
              if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo ) {
                this.lv_FofOrg = oData
                isSelFof = true
                break
              }
            }
            if (!isSelFof || !isSelDof) this.lv_FofOrg = {key: '0', label: '전체'}
            this.fn_GetFofCnsltData(false)
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_SetHofData
      * 설명       : 시스템 옵션 조회
      * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
      * {isEntpwRolYn[전사역할여부]=Y/N,
      * isHofRolYn[사업부역할여부]=Y/N,
      * isDofRolYn[지역단역할여부]=Y/N,
      * isEofRolYn[지점역할여부]=Y/N}
      * Return     : BCOptnMngSrchOSVO
      ******************************************************************************/
      fn_GetFofRolCallBack ( pData ) {
        console.log('fn_GetFofRolCallBack >> ', pData)
        if ( pData ) {
          this.lv_OrgAuth = pData
          let sOrgAuth = pData.isEntpwRolYn + pData.isHofRolYn + pData.isDofRolYn + pData.isEofRolYn
          console.log( '-------------------' )
          console.log( sOrgAuth )
          console.log( 'HOF::' + this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo )
          console.log( 'DOF::' + this.getStore('userInfo').getters.getUserInfo.onpstDofNo )
          console.log( 'FOF::' + this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo )
          console.log( this.getStore('userInfo').getters.getUserInfo )
          console.log( '-------------------' )
          // lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
          this.lv_SearchParam.fofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
          this.lv_SearchParam.orgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
          this.$bizUtil.selUserOrgList(this.lv_SearchParam, this.fn_OrgSearchCallBack)
        }
      },
      /******************************************************************************
      * Function명 : fn_GetFofRol
      * 설명       : 사용자의 조직조회 권한 확인
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_GetFofRol () {
        console.log('fn_GetFofRol >> ')
        this.lv_IsFirst = true
        this.$bizUtil.selFofRol({}, this.fn_GetFofRolCallBack )
      },
      /******************************************************************************
      * Function명  : fn_ServiceData
      * 설명        : 서비스 호출 함수 정의
      ******************************************************************************/
      fn_ServiceData(serviceName) {

        // 더보기 마지막 조회때 stndKey = '' 으로 되기때문에 마지막 검색으로 판단하고 추가 조회 안함
        if (serviceName === 'N' && this.stndKey === '') return

        let pParams = {}
        pParams.data = {}
        pParams.appId = 'sli.ipi'
        switch (serviceName) {
          case 'S': // 사전결재현황 조회
            window.vue.getStore('progress').dispatch('SKEL_LIST')
          case 'N': // 사전결재현황 더보기          
            pParams.fnctScCd = 'S'
            pParams.trnstId = 'txTSSPS96S1'
            pParams.serviceId = 'C339_F1PQL0510_S'
            pParams.reqVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0510VO'
            pParams.resVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0510VO'
            pParams.data.zSancInfoDto = {}
            pParams.data.zSancInfoDto.zaUserId = PSServiceManager.getUserInfo('userId')
            pParams.data.zSancInfoDto.zaUserEno = PSServiceManager.getUserInfo('userId')
            //pParams.data.zSancInfoDto.InqrScCd = 'S'

            //default조회
            let toDay = PSDateUtil.fn_CurrentDate()
            pParams.data.zSancInfoDto.zaInqrFromYmd = toDay.substring(0, 6) + '01'
            pParams.data.zSancInfoDto.zaInqrToYmd = toDay
            pParams.data.zSancInfoDto.zaPlanType1 = '99'
            pParams.data.zSancInfoDto.zaPlanType2 = '99'
            pParams.data.zSancInfoDto.zaSancrCd = '10'

            // 상세조회 (결재여부)
            if (this.input.sancYn !== '') {
              if (this.input.sancYn === 'Y') {
                pParams.data.zSancInfoDto.zaSancrCd = '12'         //면담완료
              }
              if (this.input.sancYn === 'N') {
                pParams.data.zSancInfoDto.zaSancrCd = '10'         //면담전
              }
              if (this.input.sancYn === 'A') {
                pParams.data.zSancInfoDto.zaSancrCd = '99'         //전체
              }
            }
            // 상세조회 (청약서발행일자)              
            if (this.input.fromYmd !== '' && this.input.toYmd !== '') {
              pParams.data.zSancInfoDto.zaInqrFromYmd = this.input.fromYmd
              pParams.data.zSancInfoDto.zaInqrToYmd = this.input.toYmd
            }
            // 상세조회 (설계종류)
            if (this.input.planType !== '') {
              if (this.input.planType === '99') {
                pParams.data.zSancInfoDto.zaPlanType1 = '99'
                pParams.data.zSancInfoDto.zaPlanType2 = '99'
              } 
              if (this.input.planType === '80') {
                pParams.data.zSancInfoDto.zaPlanType1 = '02'
                pParams.data.zSancInfoDto.zaPlanType2 = '80'
              } 
              if (this.input.planType === '82') {
                pParams.data.zSancInfoDto.zaPlanType1 = '02'
                pParams.data.zSancInfoDto.zaPlanType2 = '82'
              } 
              if (this.input.planType === '81') {
                pParams.data.zSancInfoDto.zaPlanType1 = '02'
                pParams.data.zSancInfoDto.zaPlanType2 = '81'
              } 
              if (this.input.planType === '83') {
                pParams.data.zSancInfoDto.zaPlanType1 = '02'
                pParams.data.zSancInfoDto.zaPlanType2 = '83'
              } 
              if (this.input.planType === '84') {
                pParams.data.zSancInfoDto.zaPlanType1 = '03'
                pParams.data.zSancInfoDto.zaPlanType2 = '84'
              } 
            }

            // 상세조회 (지역단/지점별)
            if (this.input.fofOrgNo !== '') {
              pParams.data.zSancInfoDto.zaHofOrgNo = this.input.hofOrgNo
              pParams.data.zSancInfoDto.zaDofOrgNo = this.input.dofOrgNo
              pParams.data.zSancInfoDto.zaFofOrgNo = this.input.fofOrgNo
            }

            pParams.data.zSancInfoDto.zPagingDto = {
              zaFnctScCd: serviceName,
              zaPageRowCnt: 30,
              zaStndKey: (serviceName === 'S') ? '' : this.stndKey
            }
            break
         default:
            break
        } // end switch
        pParams.srnId = this.$options.screenId // 대표 화면 명        
        PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
      },

      /************************************************************************************************
      * Function명  : fn_ServiceResultHandler
      * 설명        : 서비스 호출 결과 함수 정의
      ************************************************************************************************/
      fn_ServiceResultHandler(event, serviceName) {
        let lv_data = event.data
        let stndKeyList = event.trnstComm.stndKeyList
        let that = this

        switch (serviceName) {
          case 'S': // 사전결재현황 조회
            window.vue.getStore('progress').dispatch('PART')
          case 'N': // 사전결재현황 더보기
            this.stndKey = lv_data.zsancInfoDto.zpagingDto.zaStndKey

            let t_rslt
            if (lv_data.zsancInfoDto && lv_data.zsancInfoDto.zsancListDto) {
              t_rslt = lv_data.zsancInfoDto.zsancListDto
            }
            let t_rowIndex = this.rsltItemList.length
            if (t_rslt && t_rslt.length > 0) {
              t_rslt.forEach(pItem => {

                pItem.id = t_rowIndex++
                pItem.zaPblYmdLabel = pItem.zaPblYmd.substring(4, 6) + '-' + pItem.zaPblYmd.substring(6, 8)
                pItem.zaUserId = lv_data.zsancInfoDto.zaUserId

                if (pItem.zaSancrYmd.trim() === '') {
                  pItem.zaSancrYmdLabel = '-'
                } else {
                  pItem.zaSancrYmdLabel = pItem.zaSancrYmd.substring(0, 4) + '-' + pItem.zaSancrYmd.substring(4, 6) + '-' + pItem.zaSancrYmd.substring(6, 8)
                }

                if (pItem.zaOfrAddmInfoScCd === '80') {
                  pItem.zaPlanTypeNm = '본인계약'
                } else if (pItem.zaOfrAddmInfoScCd === '82') {
                  pItem.zaPlanTypeNm = '분산모집계약'
                } else if (pItem.zaOfrAddmInfoScCd === '81') {
                  pItem.zaPlanTypeNm = '단기반복해지계약'
                } else if (pItem.zaOfrAddmInfoScCd === '83') {
                  pItem.zaPlanTypeNm = 'JUST달성계약'
                } else if (pItem.zaOfrAddmInfoScCd === '84') {
                  pItem.zaPlanTypeNm = '대체계약'
                }

                if (pItem.zaSancrCd === '10') {
                  pItem.zaSancrCdNm = '면담전'
                } else if (pItem.zaSancrCd === '12') {
                  pItem.zaSancrCdNm = '면담완료'
                }

                this.rsltItemList.push(pItem)
              })

              this.orgItems = this.rsltItemList
            
              this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
                for(let i = 0 ; i < this.orgItems.length; i++){
                  this.$refs['expItem_'+i].isExpand = true // 아코디언 펼침 처리
                }
              });
            } else {
              if (serviceName === 'S') this.fn_TableStat() // 조회결과 없음 처리
              this.getStore('confirm').dispatch('ADD', '조회할 데이터가 없습니다.')
            }
   
            this.rsltCnt = (this.rsltItemList)? this.rsltItemList.length : 0
            this.isNodata = (this.rsltCnt <= 0)? true : false

            break
          default:
            break
        } // end switch
      },

      /************************************************************************************************
      * Function명  : fn_ServiceFaultHandler
      * 설명        : 에러 이벤트
      ************************************************************************************************/
      fn_ServiceFaultHandler(event, serviceName) {
        window.vue.getStore('progress').dispatch('PART')
        switch (serviceName) {
          case 'S':
            this.fn_TableStat()
            if (event && event.msgCd !== 'EFWS034') {
              this.getStore('confirm').dispatch('ADD', event.msgDesc)
            } else {
              // 다른 시스템 호출 간 오류가 발생하였습니다.
              this.getStore('confirm').dispatch('ADD', '데이터 없는 경우이니\n확인후 다시 시도하세요')
            } // end if          
            break
          case 'T':
            this.getStore('confirm').dispatch('ADD', event.msgDesc)
            break  
          default:
            break
        } // end switch
      
        this.rsltCnt = (this.rsltItemList)? this.rsltItemList.length : 0 
      },

      /************************************************************************************************
      * Function명  : fn_TableStat
      * 설명        : 데이터 없는 페이지 설정
      * 
      ************************************************************************************************/
      fn_TableStat() {
        this.isNodata = true
        this.rsltItemList.splice(0, this.rsltItemList.length) //바인딩 이벤트 강제 발생 splice 로 삭제 처리
      },

      // closeConfirmPopup() {this.$refs.confirmPopup.close()},
      // doConfirm() {
      //   this.$refs.stackableSnackbar.show({text:'사전결재를 하였습니다.'})
      //   this.$refs.confirmPopup.close()
      // },

      /******************************************************************************
      * Function명 : fn_openPopup
      * 설명 : 결재처리 팝업 연계
      ******************************************************************************/
      fn_openPopup: function (item) {

        this.itemData621P = item

        const popMSPPS621P = this.$bottomModal.open(MSPPS621P, {
          properties: {
            itemData: this.itemData621P
          },
          listeners: {
            // 대상팝업 confirmPopup $emit 이벤트명 
            confirmPopup: (rtnData) => {
              this.$refs.stackableSnackbar.show({text:'사전면담 완료되었습니다.'})
              this.$bottomModal.close(popMSPPS621P) // 모달 닫기
              //this.$refs.confirmPopup.close()
              this.fn_Reset()            
              this.fn_ServiceData('S')              
            },
            // 대상팝업 cancelPopup $emit 이벤트명 
            cancelPopup: (rtnData) => {
              this.$bottomModal.close(popMSPPS621P) // 모달 닫기
              //this.$refs.confirmPopup.close()
            },
            // 대상팝업 뒤로가기 $emit 이벤트명 
            onPopupClose: () => {
              this.$bottomModal.close(popMSPPS621P) // 모달 닫기
              //this.$refs.confirmPopup.close()
            },            
          }
        })
      },

      openOrgPopup() {this.$refs.orgPopup.open()},

      closeOrgPopup() {this.$refs.orgPopup.close()},
      
      inputOrgData(e) {this.orgData = e},
      
      selectOrg() {
        document.querySelector('.directiveButton.selectOrgBtn').classList.add('checked')
        for ( let selFof of this.lv_FofOrgData ) {
          if ( this.lv_FofOrg.key === selFof.key ) {
            this.orgNm = selFof.label
            break
          }
        }
        this.$refs.orgPopup.close()

        this.input.hofOrgNo = this.lv_HofOrg.key
        if (this.lv_DofOrg.key === '0') {
          this.input.dofOrgNo = '99999999'
        } else {
          this.input.dofOrgNo = this.lv_DofOrg.key
        }
        if (this.lv_FofOrg.key === '0') {
          this.input.fofOrgNo = '99999999'
        } else {
          this.input.fofOrgNo = this.lv_FofOrg.key
        }
        this.fn_Reset()
        this.fn_ServiceData('S')
      },
      
      directiveDropdownInput1() {
        document.querySelector('.directiveDropdown.selectStateDropdown').classList.add('checked')
        
        if (this.state1 === '면담전') {
          this.input.sancYn = 'N'
        } else if (this.state1 === '면담완료') {
          this.input.sancYn = 'Y'
        } else if (this.state1 === '전체') {
          this.input.sancYn = 'A'
        } else {
          this.input.sancYn = ''
        }
        this.fn_Reset()            
        this.fn_ServiceData('S')
      },
      
      openApprovalDatePopup() {this.$refs.approvalDatePopup.open()},
      
      closeApprovalDatePopup() {this.$refs.approvalDatePopup.close()},
      
      selectApprovalDate() {
        this.requestDate = this.dateValue1 + " ~ " + this.dateValue2
        document.querySelector('.directiveButton.approvalDateBtn').classList.add('checked')
        this.$refs.approvalDatePopup.close()
        
        if (this.dateValue1.trim() !== '' && this.dateValue2.trim() !== '') {
          this.input.fromYmd = this.dateValue1.replaceAll ('-','')
          this.input.toYmd = this.dateValue2.replaceAll ('-','')
        }
        this.fn_Reset()            
        this.fn_ServiceData('S')        
      },
      
      openPlanTypePopup() {this.$refs.planTypePopup.open()},
      
      closePlanTypePopup() {this.$refs.planTypePopup.close()},
      
      selectPlanType() {
        if (this.planTypeSel.key === '99') {
          this.planType = '전체'
        } else if (this.planTypeSel.key === '80') {
          this.planType = '본인계약'
        } else if (this.planTypeSel.key === '82') {
          this.planType = '분산모집계약'
        } else if (this.planTypeSel.key === '81') {
          this.planType = '단기반복해지계약'
        } else if (this.planTypeSel.key === '83') {
          this.planType = 'JUST달성계약'
        } else if (this.planTypeSel.key === '84') {
          this.planType = '대체계약'
        } else {
          this.planType = '설계 종류'
        }

        if (this.planTypeSel.key !== '') {
          document.querySelector('.directiveButton.planTypeBtn').classList.add('checked')
        }
        this.$refs.planTypePopup.close()

        if (this.planTypeSel.key !== '') {
          this.input.planType = this.planTypeSel.key
        }
        this.fn_Reset()            
        this.fn_ServiceData('S')
      },
      /******************************************************************************
      * Function명  : fn_ScrollBottomHandler
      * 설명        : Scroll Bottom 핸들러
      ******************************************************************************/
      fn_ScrollBottomHandler() {
        this.fn_ServiceData('N')
      },
      /******************************************************************************
      * Function명  : fn_SetOrderByFields
      * 설명        : 정렬 컴포넌트 필드정의 셋팅
      *               화면에서 소트 컨트롤 방식 셋팅 fn_SortChangeHandler 에서 처리
      * 
      ******************************************************************************/
      fn_SetOrderByFields () {
        this.sortFieldList = [
           {field : 'zaSancrCd'      , label : '면담상태'     , sort: 'DESC'}
          ,{field : 'zaPblYmd'       , label : '청약서발행일'  , sort: 'DESC'}
          ,{field : 'clctCnsltNm'    , label : 'FC명'         , sort: 'ASC'}
          ,{field : 'zaCustNm'       , label : '고객명'       , sort: 'ASC'}
          ,{field : 'zaPlanId'       , label : '설계번호'     , sort: 'ASC'}
          ,{field : 'zaPlanType'     , label : '설계유형'     , sort: 'ASC'}
        ]
      },
      /******************************************************************************
      * Function명 : fn_Reset
      * 설명       : 초기화
      ******************************************************************************/
      fn_Reset: function () {
        this.stndKey = ''        
        this.rsltItemList.splice(0, this.rsltItemList.length) //바인딩 이벤트 강제 발생 splice 로 삭제 처리
      }
    }
  };
</script>